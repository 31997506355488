import { reducers } from '@lls/front/staticImports'
import { hasWindowObject } from '@lls/utils'
import { applyMiddleware, combineReducers, createStore } from 'redux'
export const configureStore = async ({ initialState }) => {
  const loggerMiddleware =
    hasWindowObject() && process.env.CONF_ENV !== 'production'
      ? // avoid TLA for webpack..

        await import('redux-logger').then(m => {
          const createLogger = m.createLogger || m.default.createLogger
          return createLogger({
            duration: true,
            timestamp: false,
            collapsed: true
          })
        })
      : undefined
  const store = createStore(
    combineReducers(reducers),
    initialState,
    loggerMiddleware ? applyMiddleware(loggerMiddleware) : undefined
  )

  if (import.meta.hot) {
    import.meta.hot.accept(newModule => {
      store.replaceReducer(newModule)
    })
  }

  return store
}

export default configureStore
